<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="exportb">
      <heade></heade>
      <div class="con">
          <div class="box">
              <div class="p1"><span @click="dianji_fanhui">返回</span>{{text1}}</div>
              <div class="img"><img src="../../assets/daochu.png" alt="">{{text2}}</div>
              <p class="p2">{{text3}}</p>
              <p class="p3" @click="moban_xiazai">导出</p>
          </div>
      </div>
      <foote></foote>
    </div>
</template>

<script>
import $ from 'jquery'
import heade from '../../components/Heade'
import foote from '../../components/Foote'
export default {
  name: 'exportb',
  data () {
    return {
      text1: '',
      text2: '',
      text3: '',
      leixing: '',
      ent_id: '',
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/'
      // url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
      //url:'http://192.168.3.5:8080/hwb_client/V1.0.0/'
    }
  },
  components: { heade, foote },
  created () {
    if (sessionStorage.getItem('pc_pc_pc_daochu') != null && sessionStorage.getItem('pc_pc_pc_daochu') != undefined && sessionStorage.getItem('pc_pc_pc_daochu') != '') {
      this.leixing = sessionStorage.getItem('pc_pc_pc_daochu')
      if (this.leixing == '产品') {
        this.text1 = '批量导出产品信息',
        this.text2 = '导出产品',
        this.text3 = '法律声明：企业产品导出服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      } else if (this.leixing == '人员') {
        this.text1 = '批量导出人员信息',
        this.text2 = '导出人员',
        this.text3 = '法律声明：企业人员导出服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      } else if (this.leixing == '科目') {
        this.text1 = '批量导出核算科目信息',
        this.text2 = '导出核算科目',
        this.text3 = '法律声明：企业核算科目导出服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      }
    }
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
  },
  methods: {
    //   下载多个文件
    multiDownLoad (val) {
      for (let i = 0; i < val.length; i++) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i]
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }
    },
    // 模板下载
    moban_xiazai () {
      if (this.leixing == '人员') {
        const url = this.url + 'export_staff?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        this.multiDownLoad(data)
      } else if (this.leixing == '科目') {
        const url = this.url + 'export_subject?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        this.multiDownLoad(data)
      } else if (this.leixing == '产品') {
        const url = this.url + 'export_prod?ent_id=' + this.ent_id + '&excel_type=2'
        const data = [url]
        this.multiDownLoad(data)
      }
    },
    dianji_fanhui () {
      window.history.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.exportb {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        background:#E7E8EB;
        padding-top:2.2rem;
        padding-bottom: 0.56rem;
        margin: 0 auto;
        min-height: 5.5rem;
        .box{
            background: #fff;
            width:12rem;
            height:5.5rem;
            // padding: 0 0.6rem;
            margin: 0 auto;
            .p1{
                height:0.8rem;
                background: #ab7eee;
                padding: 0 0.6rem;
                font-size: 0.18rem;
                color:#fff;
                display: flex;
                align-items: center;
                span{
                    padding: 0.05rem 0.1rem;
                    border:1px solid #ccc;
                    border-radius: 0.03rem;
                    color:#1A2533;
                    background: #fff;
                    margin-right: 0.2rem;
                    cursor: pointer;
                }
            }
            .img{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.22rem;
                color:#4C4A4D;
                margin-top:1.33rem;
                img{
                    height:0.26rem;
                    width:0.26rem;
                    margin-right: 0.22rem;
                }
            }
            .p2{
                font-size: 0.12rem;
                color:#A9A9A9;
                padding: 0 0.6rem;
                margin-top: 0.95rem;
            }
            .p3{
                font-size: 0.16rem;
                color:#888888;
                height:0.38rem;
                width:1.54rem;
                border:0.01rem solid #888888;
                line-height: 0.38rem;
                text-align: center;
                margin: 0 auto;
                margin-top: 0.5rem;
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
        background:#E7E8EB;
      }
    }
}
@media screen and (max-width: 12rem) {
    .exportb {
        width: 12rem;
        background:#E7E8EB;
    }
}
</style>
